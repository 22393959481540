<template>
  <div>
    <v-container>
      <v-row class="text-center my-5">
        <v-col class="mb-4 mx-auto mt-n4 selectbox" md="8">
          <v-card flat class="card-border">
            <v-card-text>
              <v-row class="mx-0" style="display: flex">
                <v-col
                  class="d-flex"
                  md="12"
                  style="flex-wrap: wrap; justify-content: center"
                >
                  <v-hover
                    v-for="cat in categories"
                    :key="cat.id"
                    class="mr-5 mb-5 child"
                  >
                    <template v-slot="{ hover }">
                      <v-card
                        class="card-color d-flex align-center"
                        min-width="150px"
                        style="height: 150px"
                        @click="
                          selectedCategory = cat.id;
                          getService();
                        "
                        :class="`elevation-${hover ? 16 : 3}`"
                        :color="
                          hover || selectedCategory == cat.id
                            ? 'teal lighten-1'
                            : ''
                        "
                        :style="
                          hover || selectedCategory == cat.id
                            ? 'color: white; transform: scale(1.1);'
                            : ''
                        "
                      >
                        <div style="margin-left: auto; margin-right: auto">
                          <v-icon
                            class="ma-2"
                            x-large
                            dark
                            :color="
                              hover || selectedCategory == cat.id
                                ? 'white'
                                : 'primary'
                            "
                          >
                            {{ cat.icon }}
                          </v-icon>
                          <p style="font-weight: 700">{{ cat.text }}</p>
                        </div>
                      </v-card>
                    </template>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="mx-auto mt-n4 selectdefault" md="8">
          <v-card flat class="card-border">
            <v-card-text>
              <v-select
                :items="categories"
                v-model="selectedCategory"
                @change="getService()"
                item-value="id"
                label="Xizmatni tanlang"
                solo
                rounded
              ></v-select>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- <v-col class="mb-5" cols="12">
          <h2 class="headline font-weight-bold mb-3">What's next?</h2>

          <v-row justify="center"> </v-row>
        </v-col> -->
        <v-col cols="12 mx-auto" md="8">
          <v-autocomplete
            v-if="selectedCategory != 3 && selectedCategory != 7"
            :label="searchTitle"
            v-model="selectedService"
            :items="services"
            item-text="name"
            item-value="id"
            single-line
            solo
            hide-no-data
            rounded
            class="card-color"
            @change="getList()"
          >
            <template v-slot:append>
              <v-icon color="#2196f3"> mdi-magnify </v-icon>
            </template>
            <template v-slot:clear-icon>
              <v-icon color="#2196f3"> mdi-magnify </v-icon>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-if="selectedCategory == 3"
            :label="searchTitle"
            v-model="selectedService"
            :items="services"
            :item-text="getItemText"
            item-value="id"
            single-line
            solo
            hide-no-data
            rounded
            class="card-color"
            @change="getList()"
          >
            <template v-slot:append>
              <v-icon color="#2196f3"> mdi-magnify </v-icon>
            </template>
            <template v-slot:clear-icon>
              <v-icon color="#2196f3"> mdi-magnify </v-icon>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-if="selectedCategory == 7"
            :label="searchTitle"
            v-model="selectedService"
            :items="services"
            item-text="text"
            item-value="id"
            single-line
            solo
            hide-no-data
            rounded
            class="card-color"
          >
            <template v-slot:append>
              <v-icon color="#2196f3"> mdi-magnify </v-icon>
            </template>
            <template v-slot:clear-icon>
              <v-icon color="#2196f3"> mdi-magnify </v-icon>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col v-if="selectedCategory == 7" cols="12 mx-auto" md="8">
          <v-row class="text-center" v-if="selectedService == 1">
            <v-col cols="4" class="mx-auto mt-n4">
              <v-select
                :items="carTypes"
                v-model="carType"
                label="Inomarka turi"
                item-value="id"
                item-text="text"
                solo
                rounded
              ></v-select>
            </v-col>
            <v-col cols="4" class="mx-auto mt-n4">
              <v-text-field
                solo
                rounded
                v-model="carPrice"
                placeholder="Mashina narxini kiriting"
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="mx-auto mt-n4">
              <v-text-field
                solo
                rounded
                v-model="carDvigatel"
                placeholder="Dvigatel hajmi"
              ></v-text-field>
            </v-col>

            <v-btn
              class="mx-auto mt-n1"
              large
              dense
              rounded
              hide-details
              color="teal lighten-1"
              style="color: #fff"
              dense
              @click="importCar()"
              rounded
              hide-details
              dense
              >Hisoblash</v-btn
            >
          </v-row>
          <v-row class="text-center" v-if="selectedService == 2">
            <v-col cols="4" class="mx-auto mt-n4">
              <v-select
                :items="jinslar"
                v-model="jins"
                label="Jins"
                item-value="id"
                item-text="text"
                solo
                rounded
              ></v-select>
            </v-col>
            <v-col cols="4" class="mx-auto mt-n4">
              <v-text-field
                solo
                rounded
                v-model="idealHeight"
                placeholder="Bo'yingizni kiriting"
              ></v-text-field>
            </v-col>
            <v-btn
              class="mx-auto mt-n1"
              large
              dense
              rounded
              hide-details
              color="teal lighten-1"
              style="color: #fff"
              dense
              @click="importCar()"
              rounded
              hide-details
              dense
              >Hisoblash</v-btn
            >
          </v-row>
          <v-row class="text-center" v-if="selectedService == 3">
            <v-col cols="4" class="mx-auto mt-n4">
              <v-select
                :items="alimentTypes"
                v-model="alimentSelectedType"
                label="To'lovchi daromadi"
                item-value="id"
                item-text="text"
                solo
                rounded
              ></v-select>
            </v-col>
            <v-col cols="4" class="mx-auto mt-n4">
              <v-select
                :items="alimentChildrens"
                v-model="alimentSelectedChild"
                label="Farzandlar soni"
                item-value="id"
                item-text="text"
                solo
                rounded
              ></v-select>
            </v-col>
            <v-col cols="4" class="mx-auto mt-n4">
              <v-text-field
                v-if="alimentSelectedType && alimentSelectedType == 1"
                solo
                rounded
                v-model="alimentSalary"
                placeholder="Maoshni kiriting"
              ></v-text-field>
            </v-col>
            <v-btn
              class="mx-auto mt-n1"
              large
              dense
              rounded
              hide-details
              color="teal lighten-1"
              style="color: #fff"
              dense
              @click="importCar()"
              rounded
              hide-details
              dense
              >Hisoblash</v-btn
            >
          </v-row>
          <v-row class="text-center" v-if="selectedService == 4">
            <v-col cols="4" class="mx-auto mt-n4">
              <v-select
                :items="fitrTypes"
                v-model="selectedFitrType"
                label="Nimadan beriladi?"
                item-value="id"
                item-text="text"
                solo
                rounded
              ></v-select>
            </v-col>
            <v-col cols="4" class="mx-auto mt-n4">
              <v-text-field
                solo
                rounded
                v-model="familyNumber"
                placeholder="Oila a'zolaringiz soni"
              ></v-text-field>
            </v-col>
            <v-btn
              class="mx-auto mt-n1"
              large
              dense
              rounded
              hide-details
              color="teal lighten-1"
              style="color: #fff"
              dense
              @click="importCar()"
              rounded
              hide-details
              dense
              >Hisoblash</v-btn
            >
          </v-row>
          <v-row class="text-center" v-if="selectedService == 5">
            <v-col cols="4" class="mx-auto mt-n4">
              <v-select
                :items="fitrTypes"
                v-model="selectedFitrType"
                label="Nimadan beriladi?"
                item-value="id"
                item-text="text"
                solo
                rounded
              ></v-select>
            </v-col>
            <v-col cols="4" class="mx-auto mt-n4">
              <v-text-field
                solo
                rounded
                v-model="selectedDay"
                placeholder="Tutolmagan kunlar soni"
              ></v-text-field>
            </v-col>
            <v-btn
              class="mx-auto mt-n1"
              large
              dense
              rounded
              hide-details
              color="teal lighten-1"
              style="color: #fff"
              dense
              @click="importCar()"
              rounded
              hide-details
              dense
              >Hisoblash</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="showInfoDialog"
      persistent
      max-width="50%"
      @keydown.esc="showInfoDialog = false"
    >
      <v-card>
        <v-card-title primary-title>
          <p class="middletext">Qidiruv natijasi</p>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            x-small
            outlined
            class="mt-n4"
            fab
            @click="showInfoDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-center mt-10 largetext" style="color: #2196f3">
          <p v-if="selectedService && selectedCategory == 3" class="middletext">
            {{ services.find((o) => o.id == selectedService).code }}
            {{ services.find((o) => o.id == selectedService).prefix }}
          </p>
          <p v-if="selectedService && selectedCategory == 3">
            {{ services.find((o) => o.id == selectedService).area }}
          </p>
          <p
            v-if="
              selectedService &&
              selectedService != 16 &&
              selectedCategory != 3 &&
              selectedCategory != 4 &&
              selectedCategory != 6 &&
              selectedCategory != 7
            "
          >
            {{
              Math.round(
                services.find((o) => o.id == selectedService).jarimabhm * bhm
              )
                .toString()
                .replace(/(?!^)(\d{3})(?=(?:\d{3})*$)/g, ' $1') + ' UZS'
            }}
          </p>
          <p
            v-if="
              selectedService &&
              selectedService == 16 &&
              selectedCategory != 3 &&
              selectedCategory != 4 &&
              selectedCategory != 6 &&
              selectedCategory != 7
            "
          >
            {{
              Math.round(
                services.find((o) => o.id == selectedService).jarimabhm * pbm
              )
                .toString()
                .replace(/(?!^)(\d{3})(?=(?:\d{3})*$)/g, ' $1') + ' UZS'
            }}
          </p>
          <p v-if="selectedService && selectedCategory == 4">
            {{ services.find((o) => o.id == selectedService).price + ' UZS' }}
          </p>
          <div
            v-if="selectedService && selectedCategory == 3"
            class="mt-10 middletext"
            style="color: red"
          >
            {{ services.find((o) => o.id == selectedService).operator }}
          </div>
          <div
            v-if="selectedService && selectedCategory != 6"
            class="mt-10 middletext"
            style="color: red"
          >
            {{ services.find((o) => o.id == selectedService).name }}
          </div>
          <div
            v-if="selectedService && selectedCategory == 6"
            class="mt-10 largetext"
          >
            {{ services.find((o) => o.id == selectedService).name }}
          </div>
          <div
            v-if="selectedService && selectedCategory == 6"
            class="mt-10 middletext"
            :style="[
              services.find((o) => o.id == selectedService).state == '🐷 Xarom'
                ? { color: 'red' }
                : { color: 'rgb(33, 150, 243)' },
            ]"
          >
            {{ services.find((o) => o.id == selectedService).state }}
          </div>
          <p
            v-if="selectedService && selectedCategory == 6"
            class="mt-3 smalltext"
            style="color: rgb(33, 150, 243)"
          >
            ({{ services.find((o) => o.id == selectedService).define }})
          </p>
        </v-card-text>
        <v-card-text
          v-if="selectedCategory == 7 && selectedService == 1"
          class="text-center largetext"
          style="color: #2196f3"
        >
          <p>Jami narx: ${{ this.carAllPrice }}</p>
          <p class="middletext">Qo'shilayotgan summa: ${{ this.carTax }}</p>
        </v-card-text>
        <v-card-text
          v-if="selectedCategory == 7 && selectedService == 2"
          class="text-center largetext"
          style="color: #2196f3"
        >
          <p>Ideal vazn: {{ this.idealVes ? this.idealVes : '' }} kg</p>
          <!-- <p class="middletext">Qo'shilayotgan summa: ${{ this.carTax }}</p> -->
        </v-card-text>
        <v-card-text
          v-if="selectedCategory == 7 && selectedService == 3"
          class="text-center largetext"
          style="color: #2196f3"
        >
          <p>
            Aliment miqdori:
            {{
              this.alimentMoney &&
              this.alimentMoney.toString().indexOf('.') != -1
                ? this.alimentMoney
                    .toString()
                    .substring(0, this.alimentMoney.toString().indexOf('.'), +2)
                    .replace(/(?!^)(\d{3})(?=(?:\d{3})*$)/g, ' $1') + ' UZS'
                : this.alimentMoney &&
                  this.alimentMoney.toString().indexOf('.') == -1
                ? this.alimentMoney
                    .toString()
                    .replace(/(?!^)(\d{3})(?=(?:\d{3})*$)/g, ' $1') + ' UZS'
                : 0
            }}
          </p>
          <!-- <p class="middletext">Qo'shilayotgan summa: ${{ this.carTax }}</p> -->
        </v-card-text>
        <v-card-text
          v-if="selectedCategory == 7 && selectedService == 4"
          class="text-center middletext"
          style="color: #2196f3; margin-top: -5%"
        >
          <p>
            {{ familyNumber }} kishi yashaydigan oila uchun Fitr sadaqa miqdori:
            {{
              fitrAmount
                ? fitrAmount
                    .toString()
                    .replace(/(?!^)(\d{3})(?=(?:\d{3})*$)/g, ' $1')
                : 0
            }}
            so'm
          </p>
          <!-- <p class="middletext">Qo'shilayotgan summa: ${{ this.carTax }}</p> -->
        </v-card-text>
        <v-card-text
          v-if="selectedCategory == 7 && selectedService == 5"
          class="text-center middletext"
          style="color: #2196f3; margin-top: -5%"
        >
          <p>
            Bir kishiga {{ selectedDay }} kun uchun Fidya miqdori:
            {{
              fidyaAmount
                ? fidyaAmount
                    .toString()
                    .replace(/(?!^)(\d{3})(?=(?:\d{3})*$)/g, ' $1')
                : 0
            }}
            so'm
          </p>
          <!-- <p class="middletext">Qo'shilayotgan summa: ${{ this.carTax }}</p> -->
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <p>{{ phone }}</p> -->
  </div>
</template>

<script>
import phoneNum from '@/assets/json/phone.json';
import halalInfo from '@/assets/json/halal.json';
export default {
  name: 'HelloWorld',
  props: ['usd'],
  data() {
    return {
      carPrice: null,
      carDvigatel: null,
      carAllPrice: '',
      carTax: '',
      carType: null,
      idealHeight: null,
      idealVes: null,
      jins: null,
      alimentSalary: null,
      alimentNoSalary: 3500000,
      alimentSelectedType: null,
      alimentSelectedChild: null,
      alimentMoney: null,
      selectedFitrType: null,
      familyNumber: null,
      fitrAmount: null,
      selectedDay: null,
      fidyaAmount: null,
      showInfoDialog: false,
      selectedService: null,
      selectedCategory: 1,
      searchTitle: 'Umumiy',
      services: [],
      tab: null,
      calculators: [
        {
          id: 1,
          text: 'Inomarka boji',
        },
        {
          id: 2,
          text: 'Ideal vazn hisoblagich',
        },
        {
          id: 3,
          text: 'Aliment hisoblash',
        },
        {
          id: 4,
          text: 'Fitr sadaqasini hisoblash',
        },
        {
          id: 5,
          text: 'Fidyani hisoblash',
        },
      ],
      jinslar: [
        {
          id: 0,
          text: 'Ayol',
        },
        {
          id: 1,
          text: 'Erkak',
        },
      ],
      fitrTypes: [
        {
          id: 12000,
          text: 'Bug`doy (2 kg)',
        },
        {
          id: 20000,
          text: 'Arpa (4 kg)',
        },
        {
          id: 100000,
          text: 'Mayiz (2 kg)',
        },
        {
          id: 180000,
          text: 'Xurmo (4 kg)',
        },
      ],
      alimentTypes: [
        {
          id: 1,
          text: 'Ishlaydi',
        },
        {
          id: 2,
          text: 'Ishlamaydi',
        },
      ],
      alimentChildrens: [
        {
          id: 1,
          text: '1 ta',
        },
        {
          id: 2,
          text: '2 ta',
        },
        {
          id: 3,
          text: '3 va undan ortiq',
        },
      ],

      carTypes: [
        {
          id: 1,
          text: "1 yil bo'lmagan",
        },
        {
          id: 2,
          text: '1-3 yil orasida',
        },
        {
          id: 3,
          text: "3 yildan ko'p",
        },
        {
          id: 4,
          text: 'Elektromobil',
        },
      ],
      categories: [
        {
          id: 1,
          icon: 'mdi-format-list-bulleted-square',
          text: 'Umumiy',
          route: '/',
        },
        {
          id: 2,
          icon: 'mdi-office-building',
          text: 'Davlat boji',
          route: '/',
        },
        {
          id: 3,
          icon: 'mdi-phone-log',
          text: 'Telefon raqamlar',
          route: '/',
        },
        {
          id: 4,
          icon: 'mdi-lightning-bolt-circle',
          text: 'Kommunal',
          route: '/',
        },
        {
          id: 5,
          icon: 'mdi-alert-circle',
          text: 'Jarimalar',
          route: '/',
        },
        {
          id: 6,
          icon: 'mdi-alpha-e-box',
          text: 'ECode',
          route: '/',
        },
        {
          id: 7,
          icon: 'mdi-calculator-variant',
          text: 'Kalkulyator',
          route: '/',
        },
      ],
      phone: phoneNum,
      yhq: [
        {
          id: 1,
          name: 'Xavfsizlik kamari va motoshlemdan foydalanmaslik',
          jarimabhm: '0.5',
        },
        {
          id: 2,
          name: 'Texnik ko`rikdan o`tkazilmagan va mahsus jihozlar bilan jihozlanmaganligi',
          jarimabhm: '0.5',
        },
        {
          id: 3,
          name: 'Qayta jihozlangan hamda rul boshqaruvi va tormoz tizimi nosozligi',
          jarimabhm: '1',
        },
        {
          id: 4,
          name: 'Xalqaro va shaharlararo yo`lovchi tashuvchi avtobuslarda taxograflarsiz boshqarish',
          jarimabhm: '3',
        },
        {
          id: 5,
          name: 'Foydalanish man etilgan hamda davlat raqam belgisi yechib transportni boshqarish',
          jarimabhm: '5',
        },
        {
          id: 6,
          name: 'Davlat raqam belgisi yasama va boshqa transportga tegishli',
          jarimabhm: '10',
        },

        {
          id: 11,
          name: 'Yuk tashish qoidalarini, xuddi shuningdek shatakka olish qoidalarini buzish',
          jarimabhm: '5',
        },

        {
          id: 19,
          name: 'Ishlab chiqargan korxona nazarda tutmagan tovush chiqaruvchi va yorituvchi qurilmalarni o‘rnatish, xuddi shuningdek ularni o‘zgartirib o‘rnatish',
          jarimabhm: '1',
        },
        {
          id: 20,
          name: 'Haydovchilarning telefondan foydalanishi',
          jarimabhm: '3',
        },
        {
          id: 21,
          name: 'Monitordan (displeydan) tele-, videodasturlarni tomosha qilish uchun foydalanish',
          jarimabhm: '1',
        },
        {
          id: 22,
          name: 'Salonining old qismiga o‘zboshimchalik bilan monitor (displey) o‘rnatish',
          jarimabhm: '2',
        },
        {
          id: 23,
          name: 'Belgilangan harakat tezligini soatiga 20 kilometrdan ko‘p bo‘lmagan kattalikda oshirib yuborishi',
          jarimabhm: '1',
        },
        {
          id: 24,
          name: 'Belgilangan harakat tezligini soatiga 20 kilometrdan ko‘p bo‘lmagan kattalikda oshirib yuborishi',
          jarimabhm: '1',
        },
        {
          id: 25,
          name: 'Belgilangan harakat tezligini soatiga 20 kilometrdan ko`p, lekin 40 kilomterdan ko‘p bo‘lmagan kattalikda oshirib yuborishi',
          jarimabhm: '5',
        },
        {
          id: 26,
          name: 'Belgilangan harakat tezligini soatiga 40 kilometrdan ortiq kattalikda oshirib yuborishi',
          jarimabhm: '9',
        },
        {
          id: 27,
          name: 'Svetoforning taqiqlovchi signaliga yoki yo‘l harakatini tartibga soluvchining taqiqlovchi ishorasiga bo‘ysunmasligi',
          jarimabhm: '2',
        },
        {
          id: 28,
          name: 'Yo‘lning qarama-qarshi harakatlanish uchun mo‘ljallangan tomoniga yoki bo‘lagiga yo‘l harakati qoidalarini buzgan holda chiqishi',
          jarimabhm: '10',
        },
        {
          id: 29,
          name: 'To‘xtash yoki to‘xtab turish qoidalarini buzishi',
          jarimabhm: '2',
        },
        {
          id: 30,
          name: 'Temir yo‘lning o‘tish joylaridan o‘tish qoidalarini buzishi',
          jarimabhm: '3',
        },
        {
          id: 31,
          name: 'Alkogolli ichimlikdan, giyohvand modda ta’siridan yoki o‘zgacha tarzda mast holda boshqarishi',
          jarimabhm: '25',
        },
        {
          id: 32,
          name: 'Yo‘l harakati qoidalarini buzishi jabrlanuvchiga yengil tan jarohati yoki ancha miqdorda moddiy zarar yetkazilishiga olib kelsa',
          jarimabhm: '7',
        },
        {
          id: 33,
          name: 'Yo‘l-transport hodisasi qatnashchilarining belgilangan qoidalarni buzgan holda hodisa yuz bergan joydan ketib qolishi',
          jarimabhm: '30',
        },
        {
          id: 34,
          name: 'Piyodalarning yo‘l harakatini tartibga soluvchi signallarga bo‘ysunmasligi, ularning yo‘lning harakat qismini belgilanmagan joylardan kesib o‘tishi',
          jarimabhm: '0.3',
        },
        {
          id: 35,
          name: 'Harakatlanayotgan vaqtda yo‘lovchi tomonidan tana qismlarini (qo‘ldan tashqari) salondan chiqarish',
          jarimabhm: '1',
        },
        {
          id: 36,
          name: 'Korxonalar, muassasalar va tashkilotlarga qarashli transport vositalaridan shaxsiy boylik orttirish maqsadida foydalanish',
          jarimabhm: '5',
        },
        {
          id: 37,
          name: 'Poyezdlarning vagonlari, avtotransport vositalari va shahar yo‘lovchilar transportidan chiqindi yoki boshqa narsalarni tashlab yuborish',
          jarimabhm: '0.5',
        },
        {
          id: 38,
          name: 'Boshqarish huquqidan mahrum etilgan shaxslarning bunday vositalarni boshqarishi',
          jarimabhm: '10',
        },
      ],
      default: [
        {
          id: 7,
          name: 'Suyunchi puli (Bola tug`ilganida beriladigan pul)',
          jarimabhm: '2',
        },
        {
          id: 8,
          name: 'Talabalarga ijara to‘lovining bir qismini qoplab berish',
          jarimabhm: '1',
        },
        {
          id: 9,
          name: 'Elektron raqamli imzo olish (ERI)',
          jarimabhm: '0.07',
        },
        {
          id: 10,
          name: 'Otmga Hujjat topshirish (Abituriyentlar)',
          jarimabhm: '0.5',
        },
        {
          id: 12,
          name: 'ID karta',
          jarimabhm: '0.89',
        },
        {
          id: 13,
          name: 'Horijga chiqish biometrik pasporti',
          jarimabhm: '1',
        },
        {
          id: 14,
          name: '16 yoshga to`lmaganlar uchun horijga chiqish biometrik pasporti',
          jarimabhm: '0.8',
        },
        {
          id: 15,
          name: 'Bir million o`zbek dasturchilari (one million uzbek coders) kompyuter xarid qilish uchun kredit',
          jarimabhm: '25',
        },
        {
          id: 16,
          name: 'Dafn puli',
          jarimabhm: '4',
        },
        {
          id: 17,
          name: 'IMEI ro`yxatdan o`tkazish',
          jarimabhm: '0.2',
        },
        {
          id: 18,
          name: 'Bir oylik harbiy xizmat',
          jarimabhm: '20',
        },
      ],
      govboj: [
        {
          id: 39,
          name: 'Ov qilishga ruhsat',
          jarimabhm: '0.2',
        },
        {
          id: 40,
          name: 'Shahar atrofi va shaharlararo yo`lovchi tashuvchi mikroavtobus va avtobus litsenziyasi (14 o`ringacha)',
          jarimabhm: '1',
        },
        {
          id: 41,
          name: 'Shahar atrofi va shaharlararo yo`lovchi tashuvchi mikroavtobus va avtobus litsenziyasi (14 o`rindan ortiq)',
          jarimabhm: '2.5',
        },
        {
          id: 42,
          name: 'Shahar atrofi va shaharlararo yo`lovchi tashuvchi yrngil avtomobil litsenziyasi (4 o`ringacha)',
          jarimabhm: '2',
        },
        {
          id: 43,
          name: 'Shahar atrofi va shaharlararo yo`lovchi tashuvchi yrngil avtomobil litsenziyasi (4 o`rindan ortiq)',
          jarimabhm: '3.5',
        },
        {
          id: 44,
          name: 'Xalqaro yo`nalishda yo`lovchi tashish avtobuslar uchun litsenziyasi',
          jarimabhm: '28',
        },
        {
          id: 45,
          name: 'Xalqaro yo`nalishda yo`lovchi tashish mikroavtobuslar uchun litsenziyasi',
          jarimabhm: '22',
        },
        {
          id: 46,
          name: 'Xalqaro yo`nalishda yo`lovchi tashish yengil avtomobillar uchun litsenziyasi',
          jarimabhm: '7',
        },
        {
          id: 47,
          name: 'Yuklarni xalqaro yo`nalishda  tashish uchun litsenziyasi',
          jarimabhm: '7',
        },
        {
          id: 48,
          name: 'Advokatlik litsenziyasi',
          jarimabhm: '1',
        },
        {
          id: 49,
          name: 'Notarial faoliyat litsenziyasi',
          jarimabhm: '1',
        },
        {
          id: 50,
          name: 'Dori vositalari va tibbiy buyumlarni realizatsiya qilish litsenziyasi',
          jarimabhm: '10',
        },
        {
          id: 51,
          name: 'Alkogol mahsulotlari ulgurji savdosi litsenziyasi',
          jarimabhm: '200',
        },
      ],

      kommunal: [
        { id: 1, name: 'Suv', price: 400 },
        { id: 2, name: 'Elektr', price: 295 },
        { id: 3, name: 'Gaz', price: 380 },
        { id: 4, name: 'Chiqindi', price: 4500 },
      ],
      halol: halalInfo,
      bhm: '300000',
      pbm: '324000',
    };
  },
  methods: {
    importCar() {
      if (this.selectedService == 1) {
        let dvigatelPrice = 0;
        let stavka = 0;
        let qqs = 0;
        let car15percent = this.carPrice * 0.15;
        let car30percent = this.carPrice * 0.3;
        let car40percent = this.carPrice * 0.4;
        // let usd = this.usd.Rate;
        // let usd = this.usd.Rate;
        // let usdtobhm = 300000/usd.substr(0,5);
        // usdtobhm = usdtobhm.toString().substr(0,4);
        let usdtobhm = 26.4;
        // console.log(usdtobhm);
        // console.log(this.carDvigatel);
        let utilizatsiya = 0;
        if (this.carType == 1) {
          if (this.carDvigatel <= 1000) {
            dvigatelPrice = this.carDvigatel * 0.4;
            utilizatsiya = usdtobhm * 30;
            // console.log(utilizatsiya);
          }
          if (this.carDvigatel > 1000 && this.carDvigatel <= 1500) {
            dvigatelPrice = this.carDvigatel * 0.6;
            utilizatsiya = usdtobhm * 120;
          }
          if (this.carDvigatel > 1500 && this.carDvigatel <= 1800) {
            dvigatelPrice = this.carDvigatel * 0.8;
            utilizatsiya = usdtobhm * 120;
          }
          if (this.carDvigatel > 1800 && this.carDvigatel <= 3000) {
            dvigatelPrice = this.carDvigatel * 1.25;
            utilizatsiya = usdtobhm * 180;
          }
          stavka = car15percent + dvigatelPrice;
          qqs = (parseInt(this.carPrice) + stavka) * 0.12;
          this.carAllPrice =
            stavka + qqs + utilizatsiya + parseInt(this.carPrice);
          this.carTax = stavka + qqs + utilizatsiya;
        }
        if (this.carType == 2) {
          if (this.carDvigatel <= 1000) {
            dvigatelPrice = this.carDvigatel * 1.8;
            utilizatsiya = usdtobhm * 30;
          }
          if (this.carDvigatel > 1000 && this.carDvigatel <= 1500) {
            dvigatelPrice = this.carDvigatel * 2;
            utilizatsiya = usdtobhm * 120;
          }
          if (this.carDvigatel > 1500 && this.carDvigatel <= 1800) {
            dvigatelPrice = this.carDvigatel * 2.5;
            utilizatsiya = usdtobhm * 120;
          }
          if (this.carDvigatel > 1800 && this.carDvigatel <= 3000) {
            dvigatelPrice = this.carDvigatel * 2.5;
            utilizatsiya = usdtobhm * 180;
          }
          stavka = car30percent + dvigatelPrice;
          qqs = (parseInt(this.carPrice) + stavka) * 0.12;
          this.carAllPrice =
            stavka + qqs + utilizatsiya + parseInt(this.carPrice);
          this.carTax = stavka + qqs + utilizatsiya;
        }
        if (this.carType == 3) {
          if (this.carDvigatel <= 1000) {
            dvigatelPrice = this.carDvigatel * 1.8;
            utilizatsiya = usdtobhm * 90;
          }
          if (this.carDvigatel > 1000 && this.carDvigatel <= 1500) {
            dvigatelPrice = this.carDvigatel * 2;
            utilizatsiya = usdtobhm * 210;
          }
          if (this.carDvigatel > 1500 && this.carDvigatel <= 1800) {
            dvigatelPrice = this.carDvigatel * 2.5;
            utilizatsiya = usdtobhm * 210;
          }
          if (this.carDvigatel > 1800 && this.carDvigatel <= 3000) {
            dvigatelPrice = this.carDvigatel * 2.5;
            utilizatsiya = usdtobhm * 330;
          }
          stavka = car40percent + dvigatelPrice;
          qqs = (parseInt(this.carPrice) + stavka) * 0.12;
          this.carAllPrice =
            stavka + qqs + utilizatsiya + parseInt(this.carPrice);
          this.carTax = stavka + qqs + utilizatsiya;
        }
        if (this.carType == 4) {
          this.carAllPrice =
            parseInt(this.carPrice) + this.carPrice * 0.12 + 20;
          this.carTax = this.carPrice * 0.12 + 20;
        }
        // let priceStavka = parseInt(this.carPrice) + stavka;
      }
      if (this.selectedService == 2) {
        if (this.jins == 0) {
          this.idealVes = (this.idealHeight - 110) * 1.15;
        }
        if (this.jins == 1) {
          console.log(213123);
          this.idealVes = (this.idealHeight - 100) * 1.15;
        }
      }
      if (this.selectedService == 3) {
        if (this.alimentSelectedType == 1) {
          if (this.alimentSelectedChild == 1) {
            this.alimentMoney = this.alimentSalary / 4;
          }
          if (this.alimentSelectedChild == 2) {
            this.alimentMoney = this.alimentSalary / 3;
            console.log(this.alimentMoney.toString().indexOf('.'));
          }
          if (this.alimentSelectedChild == 3) {
            this.alimentMoney = this.alimentSalary / 2;
          }
        }
        if (this.alimentSelectedType == 2) {
          if (this.alimentSelectedChild == 1) {
            this.alimentMoney = this.alimentNoSalary / 4;
          }
          if (this.alimentSelectedChild == 2) {
            this.alimentMoney = this.alimentNoSalary / 3;
          }
          if (this.alimentSelectedChild == 3) {
            this.alimentMoney = this.alimentNoSalary / 2;
          }
        }
        // this.alimentMoney = this.alimentMoney
        //   .toString()
        //   .substring(0, this.alimentMoney.toString().indexOf('.'), +2);
        // console.log(this.alimentMoney);
      }
      if (this.selectedService == 4) {
        this.fitrAmount = this.selectedFitrType * this.familyNumber;
      }
      if (this.selectedService == 5) {
        this.fidyaAmount = this.selectedFitrType * this.selectedDay;
      }

      // console.log(jami);
      this.showInfoDialog = true;
    },
    getList() {
      this.showInfoDialog = true;
    },

    getService() {
      this.selectedService = '';
      this.services = null;
      if (this.selectedCategory == 1) {
        this.services = this.default;
      } else if (this.selectedCategory == 5) {
        this.services = this.yhq;
      } else if (this.selectedCategory == 2) {
        this.services = this.govboj;
      } else if (this.selectedCategory == 3) {
        this.services = this.phone;
      } else if (this.selectedCategory == 4) {
        this.services = this.kommunal;
      } else if (this.selectedCategory == 6) {
        this.services = this.halol;
      } else if (this.selectedCategory == 7) {
        this.services = this.calculators;
      }
      // console.log(this.services);
      this.searchTitle = this.categories.find(
        (o) => o.id == this.selectedCategory
      ).text;
    },
    getItemText(item) {
      return `${item.code}${item.prefix}`;
    },
  },
  mounted() {
    this.services = this.default;
    // this.getValyuta();
  },
  computed: {
    screenWidth() {
      return window.innerWidth;
    },
    screenHeight() {
      return window.innerHeight;
    },
  },
};
</script>
<style scoped>
.bg-body {
  height: 500px;
  background-color: #2196f3;
}
.active-class {
  background-color: white;
}
.card-border {
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: transparent;
}
.card-color {
  /* background-color: #787d83; */
  opacity: 0.9;
}
.child {
  width: 20%;
  box-sizing: border-box;
}
.selectdefault {
  display: none;
}
.largetext {
  font-size: 48px;
  font-weight: 700;
}
.middletext {
  font-size: 24px;
}
.smalltext {
  font-size: 16px;
}
@media only screen and (max-width: 600px) {
  .selectbox {
    display: none;
  }
  .selectdefault {
    display: block;
  }
  .largetext {
    font-size: 24px;
    font-weight: 700;
  }
  .middletext {
    font-size: 12px;
  }
  .v-btn--fab.v-size--x-small {
    height: 16px;
    width: 16px;
  }
}
</style>
