<template>
  <v-app style="background-color: #005a6b !important">
    <v-app-bar app color="white" dark>
      <div>
        <v-img src="@/assets/logo.gif" width="50" height="50"></v-img>
      </div>
      <div class="space-header"></div>
      <div v-for="val in main" :key="val.name" class="ml-5 header-info">
        <v-tooltip bottom color="success">
          <template v-slot:activator="{ on, attrs }">
            <p v-bind="attrs" v-on="on" class="pt-4 mr-2" style="">
              {{ val.name }}:
              <span style="white-space: nowrap">{{ val.value }}</span>
            </p>
          </template>
          <span class="tooltip-text">{{ val.full }}</span>
        </v-tooltip>
      </div>

      <v-spacer></v-spacer>
      <div
        v-for="val in valyuta"
        :key="val.code"
        class="header-currency hidden-xs-only hidden-sm-only"
        style=""
      >
        <p class="pt-4 mr-5" v-if="val.Ccy == 'USD'" style="color: #2196f3">
          {{ val.Ccy }}: {{ val.Rate }}
        </p>
        <p class="pt-4 mr-5" v-if="val.Ccy == 'EUR'" style="color: #2196f3">
          {{ val.Ccy }}: {{ val.Rate }}
        </p>
        <p class="pt-4 mr-5" v-if="val.Ccy == 'RUB'" style="color: #2196f3">
          {{ val.Ccy }}: {{ val.Rate }}
        </p>
      </div>

      <!-- <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
        color="primary"
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-main>
      <HomeView :usd="usd" />
    </v-main>
  </v-app>
</template>

<script>
import HomeView from './components/HomeView';

export default {
  name: 'App',

  components: {
    HomeView,
  },

  data() {
    return {
      usd: [],
      valyuta: [],
      headers: [
        { text: 'Nomi', value: 'title' },
        { text: 'Kodi', value: 'code' },
        { text: 'Kurs', value: 'cb_price' },
        { text: 'Sotib olish', value: 'nbu_buy_price' },
        { text: 'Sotish', value: 'nbu_cell_price' },
      ],
      main: [
        {
          name: 'MHTEM',
          value: '900 000',
          full: 'MEHNATGA HAQ TO`LASHNING ENG KAM MIQDORI',
        },
        { name: 'BHM', value: '300 000', full: 'BAZAVIY HISOBLASH MIQDORI' },
        {
          name: 'PBM',
          value: '324 000',
          full: 'PENSIYANI HISOBLASHNING BAZAVIY MIQDORI',
        },
      ],
    };
  },
  methods: {
    getValyuta() {
      this.loading = true;
      this.axios
        .get(
          'https://cors-anywhere.herokuapp.com/https://cbu.uz/oz/arkhiv-kursov-valyut/json/'
        )
        .then((response) => {
          //   const valyutalar = response.data;
          let json = response.data;
          json = json.filter(function (item) {
            return item.Ccy == 'USD' || item.Ccy == 'EUR' || item.Ccy == 'RUB';
          });
          let usd = json.filter(function (item) {
            return item.Ccy == 'USD';
          });
          this.valyuta = json;
          this.usd = usd[0];
          this.loading = false;
          //   this.valyuta = valyutalar.filter((item) => item.code.includes("EUR"));
          // https://cors-anywhere.herokuapp.com/
        });
    },
  },
  mounted() {
    this.getValyuta();
  },
};
</script>
<style scoped>
.header-info {
  color: #2196f3;
  font-weight: 700;
  font-size: 16px;
}
.header-currency {
  font-weight: 700;
  font-size: 16px;
}
.space-header {
  display: none;
}
@media only screen and (max-width: 600px) {
  .header-info {
    font-size: 6px;
  }
  .space-header {
    display: block;
    flex-grow: 1 !important;
  }
  .tooltip-text {
    font-size: 8px;
  }
}
@media only screen and (max-width: 960px) {
  .header-info {
    font-size: 12px;
  }
  .space-header {
    display: block;
    flex-grow: 1 !important;
  }
  .tooltip-text {
    font-size: 10px;
  }
}
</style>
